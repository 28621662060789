import { createSelector } from '@reduxjs/toolkit';

import { MEMBER_COMMENTS_SLICE_NAME } from './member-comments-slice';
import { MemberCommentsState } from './member-comments-slice.models';

export interface PartialState {
  [MEMBER_COMMENTS_SLICE_NAME]: MemberCommentsState;
}

export const getMemberSlice = (state: PartialState) =>
  state[MEMBER_COMMENTS_SLICE_NAME];

export const getMemberComments = createSelector(getMemberSlice, state => {
  return state;
});
