import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { Status } from '../member-slice.models';
import { MemberCommentsState } from './member-comments-slice.models';
import {
  fetchInitialMemberComments,
  fetchMemberComments,
  deleteMemberComment,
} from './member-comments-slice.actions';

export const MEMBER_COMMENTS_SLICE_NAME = 'memberComments';

const initialState: MemberCommentsState = {
  status: Status.INITIAL,
};

const buildFetchInitialMemberComments = (
  builder: ActionReducerMapBuilder<MemberCommentsState>,
) => {
  builder.addCase(
    fetchInitialMemberComments.fulfilled,
    (state, { payload }): MemberCommentsState => {
      if (state.status === Status.INITIAL) {
        return {
          status: Status.READY,
          user: payload.user,
          comments: payload.comments,
          pagination: payload.pagination,
        };
      }

      return state;
    },
  );
  builder.addCase(
    fetchInitialMemberComments.rejected,
    (state): MemberCommentsState => ({
      ...state,
      status: Status.ERROR,
    }),
  );
};

const buildFetchMemberComments = (
  builder: ActionReducerMapBuilder<MemberCommentsState>,
) => {
  builder.addCase(
    fetchMemberComments.pending,
    (state): MemberCommentsState => {
      if (state.status === Status.READY) {
        return {
          ...state,
          status: Status.PENDING,
        };
      }

      return state;
    },
  );
  builder.addCase(
    fetchMemberComments.fulfilled,
    (state, { payload }): MemberCommentsState => {
      if (
        state.status === Status.PENDING ||
        state.status === Status.PENDING_AFTER_DELETE
      ) {
        return {
          ...state,
          status: Status.READY,
          comments: payload.comments,
          pagination: payload.pagination,
        };
      }

      return state;
    },
  );
  builder.addCase(
    fetchMemberComments.rejected,
    (state): MemberCommentsState => ({
      ...state,
      status: Status.ERROR,
    }),
  );
};

const buildDeleteMemberComments = (
  builder: ActionReducerMapBuilder<MemberCommentsState>,
) => {
  builder.addCase(
    deleteMemberComment.pending,
    (state): MemberCommentsState => {
      if (state.status === Status.READY) {
        return {
          ...state,
          status: Status.PENDING,
        };
      }

      return state;
    },
  );
  builder.addCase(
    deleteMemberComment.fulfilled,
    (state): MemberCommentsState => {
      if (state.status === Status.PENDING) {
        return {
          ...state,
          status: Status.PENDING_AFTER_DELETE,
        };
      }

      return state;
    },
  );
  builder.addCase(
    deleteMemberComment.rejected,
    (state): MemberCommentsState => ({
      ...state,
      status: Status.ERROR,
    }),
  );
};

const memberPostsSlice = createSlice({
  name: MEMBER_COMMENTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    buildFetchInitialMemberComments(builder);
    buildFetchMemberComments(builder);
    buildDeleteMemberComments(builder);
  },
});

export const reducer = memberPostsSlice.reducer;
